import React from 'react';
import './App.scss';

import Countdown from './components/Countdown';

const App = () => {
  return (
    <div className="makeitgood">
    <div className="wrapper">
      <Countdown date="Septembre 15 2021 12:00" />
    </div></div>
  );
}

export default App;
